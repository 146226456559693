import axios from 'axios'

import {API_URL} from "../utils/consts";

const api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  timeout: 60000,
});

export default api