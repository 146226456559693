import React from 'react';
import {Switch, Route} from 'react-router-dom'
import {Routes} from "../routes";
import {observer} from "mobx-react-lite";

const AppRouter = observer(() => {
    return (
        <>
            <Switch>
                {Routes.map(({name, path, Component}) =>
                    <Route
                        key={path}
                        path={path}
                        component={match => <Component name={name} match={match}/>}
                        exact
                    />
                )}
            </Switch>
        </>
    );
});

export default AppRouter;