import React from 'react';

import logo from "../../img/logo_ch.svg";

const Header = () => {
    return (
        <header className="img">
            <img src={logo} alt="logo" />
            <div className="or">
                <p>
                    <span className='bold'>Зареєструйся, пройди верифікацію за допомогою Державного сервісу</span> <br className="block" /> та отримай <br/> <span className='blue bold'>100</span> <span className='blue'>грн</span> на
                    рахунок і <span className='blue bold'>100</span> <span className='blue'>грн</span> <br/> до
                    персонального бонусу
                </p>
            </div>
        </header>
    );
};

export default Header;