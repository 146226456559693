import React from 'react';
const Loader = () => {

    return (
        <div className='loader'>
            <div className="new_load" />
        </div>
    );
};

export default Loader;