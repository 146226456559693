import React, { Component } from 'react'
import device from "current-device";

import ico_change_currency_error from "../../img/icons/change-currency-error.png";
import ico_change_currency_error_not_mobile from "../../img/icons/change-currency-error-not-mobile.png";

class ErrorBoundary extends Component {
    state = {
        error: false,
        errorMessage: '',
        errorInfo: ''
    }

    // static getDerivedStateFromError(error) {
    //     return {error: true}
    // }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo)
        this.setState({
            error: true,
            errorMessage: error,
            errorInfo: errorInfo?.componentStack
        })
    }

    render() {

        if (this.state.error) {
            return (
                <>
                    <div className='contacts_forms registration blur'>
                        <div className="sub_form column">
                            <div className="icon">
                                <img src={device.mobile()
                                    ? ico_change_currency_error
                                    : ico_change_currency_error_not_mobile} alt=""
                                />
                            </div>
                            <h2>System ERROR</h2>
                            {this?.state?.errorMessage && this?.state?.errorMessage?.toString()
                                && <p style={{wordBreak: 'break-word'}}>{this?.state?.errorMessage?.toString()}</p>}
                        </div>
                        <form
                            autoComplete="off"
                            onSubmit={e => e.preventDefault()}
                        >
                            <div className="box button">
                                <button
                                    type='button'
                                    onClick={e => {
                                        e.preventDefault()
                                        window.location.reload()
                                    }}
                                >
                                    <span>Ок</span>
                                </button>
                            </div>
                        </form>
                        <div className="forms_bottom b_none" />
                    </div>
                </>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary