import React, {useEffect, useRef, useState} from 'react';
import PhoneInput from 'react-phone-input-2'
import { useOverlayScrollbars } from "overlayscrollbars-react";

import 'react-phone-input-2/lib/style.css'
import 'overlayscrollbars/styles/overlayscrollbars.css';

const events = {
    initialized(instance) {
        const viewport = instance.elements().viewport
        const target = instance.elements().target
        let scrollTop = viewport.scrollTop
        if (scrollTop > 0) {
            viewport.scrollTo(0, scrollTop + 6)
        }
        target.style.opacity = '1'
    }
}

const InputSelect = ({buttonDisabled, value, setValue, isoData, setIso}) => {
    const ref = useRef(null)
    const [initialize, instance] = useOverlayScrollbars({events});
    const [maxHeight, setMaxHeight] = useState(100);
    const [dropdown, setDropdown] = useState(false);

    useEffect(() => {
        if (ref) {
            let input = ref.current.numberInputRef;
            let select_button = input.nextElementSibling;
            let select_button_flag = select_button.querySelector('.selected-flag');
            let offsetParent = input.getBoundingClientRect().top - input.closest('.contacts_forms').getBoundingClientRect().top
            let currMaxHeight = input.closest('.contacts_forms').offsetHeight - input.offsetHeight - offsetParent - 50
            setMaxHeight(currMaxHeight)

            if (select_button_flag) {
                select_button_flag.tabIndex = -1;
            }

            if (select_button && ref?.current?.props?.onlyCountries && ref?.current?.props?.onlyCountries?.length === 1) {
                setDropdown(true)
                select_button.classList.add('disabled')
            }

            function handleClickOutside(event) {
                if (event.target.closest('.country-list') && select_button.classList.contains('open')) {
                    select_button.closest('.contacts_forms').classList.add('select')
                } else {
                    select_button.closest('.contacts_forms').classList.remove('select')
                    if (instance()) {
                        instance().destroy()
                    }
                }
            }
            function handleClickButton() {
                if (select_button.classList.contains('open')) {
                    select_button.closest('.contacts_forms').classList.remove('select')
                    if (ref.current.dropdownRef) {
                        instance().destroy()
                    }
                } else {
                    select_button.closest('.contacts_forms').classList.add('select')
                    setTimeout(initScroll)
                }
            }

            function initScroll() {
                if (ref.current.dropdownRef) {
                    initialize(ref.current.dropdownRef);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            select_button.addEventListener('click', handleClickButton)

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [ref, dropdown]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <PhoneInput
                ref={ref}
                value={value}
                country={(isoData && isoData.length > 0)
                    ? (isoData.filter(item => item?.iso === 'UA')[0])
                        ? 'ua'
                        : isoData[0]?.iso?.toLowerCase()
                    : 'ua'
            }
                onlyCountries={(isoData && isoData.length > 0) ? isoData.map(code => code?.iso?.toLowerCase()) : ['ua']}
                disableDropdown={dropdown}
                onChange={(phone, country) => {
                    setValue('+' + phone)
                    setIso(country.countryCode
                        ? country?.countryCode
                        : (isoData && isoData.length > 0)
                            ? (isoData.filter(item => item.iso === 'UA')[0])
                                ? 'UA'
                                : isoData[0]?.iso
                            : 'UA'
                    )
                    ref.current.numberInputRef.focus()
                }}
                countryCodeEditable={false}
                autoFocus={true}
                inputProps={{
                    name: 'phone',
                    autoComplete: 'off'
                }}
                inputClass={!buttonDisabled ? 'active' : ''}
                dropdownStyle={{maxHeight: maxHeight}}
            />
        </>
    );
};

export default InputSelect;