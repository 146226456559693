import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import 'current-device'

import Loader from "./components/Loader";
import App from './App';

import './style/_reset.min.scss'
import './style/_style.scss'
import './style/_media.scss'

ReactDOM.render(
    <Suspense fallback={<Loader/>}>
        <App/>
    </Suspense>,
    document.getElementById('root')
);

