import React from 'react';

const Footer = () => {

    return (
        <footer>
            <p>Тільки для нових користувачів.</p>
        </footer>
    );
};

export default Footer;