import api from '../http'
import {API_VALIDATION_PHONE} from "../utils/consts";

export default class RegistrationService {
    static async validate_phone(data) {
        let formData = false;
        if (window.FormData) {
            formData = new FormData();
            for (let key in data) {
                if (key !== 'iso') { // ISO key remove, only UA, REMOVE if LANGUAGES MORE 1
                    formData.append(key, data[key]);
                }
            }
            return api.post(API_VALIDATION_PHONE, formData)
                .then(response => response.data)
                .catch((e) => {
                    return {
                        error: e.message
                    }
                })
        } else {
            return {
                error: 'Error'
            }
        }
    }
}