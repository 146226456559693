import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import AppRouter from "./components/AppRouter"
import Header from "./components/Header";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {

    return (
        <BrowserRouter>
            <Header />
            <ErrorBoundary>
                <AppRouter/>
            </ErrorBoundary>
        </BrowserRouter>
    );
}

export default App;
