import React, {useEffect, useState} from 'react';
import queryString from "query-string";

import RegistrationService from "../services/RegistrationService";
import {other_links} from "../utils/consts";

import InputSelect from "../components/InputSelect";
import Footer from "../components/Footer";

import bonus from '../img/icons/bonus.png'
import new_account from '../img/icons/new_account.png'

const IndexPage = () => {
    const [userLogin, setUserLogin] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isoData] = useState([]);
    const [iso, setIso] = useState('UA');
    const [redirect, setRedirect] = useState('');
    const [newClass, setNewClass] = useState('');

    function Error(errors) {
        setLoading(false)
        if (errors) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
            setError(true);
        }
    }

    // key "ENTER" submit Forms
    async function callMyFunction() {
        if (!error && userLogin.length > 12) {
            setLoading(true)
            setButtonDisabled(true);
            setErrorMessage('')

            return await RegistrationService.validate_phone({phone: userLogin, iso, ...queryString.parse(window.location.search)})
                .then((data) => {
                    // if (userLogin !== '+380000000000') {
                    //     setNewClass('new')
                    //     setRedirect(`${other_links.redirect_link}${window.location.search && Object.keys(queryString.parse(window.location.search)).length > 0 ? `${window.location.search}&` : '?'}user_login=${userLogin.replace('+', '')}&login=registration`)
                    // } else {
                    //     setNewClass('old')
                    //     setRedirect(`${other_links.redirect_link}?user_login=${userLogin.replace('+', '')}`)
                    // }
                    if (data.hasOwnProperty('success')) {
                        if (data?.success) {
                            setNewClass('new')
                            setRedirect(`${other_links.redirect_link}${window.location.search && Object.keys(queryString.parse(window.location.search)).length > 0 ? `${window.location.search}&` : '?'}user_login=${userLogin.replace('+', '')}&login=registration&lang=uk`)
                        } else {
                            if (data?.error.length && data?.error === "Невірний номер телефону") {
                                setErrorMessage(data.error)
                                Error(true);
                            } else {
                                setNewClass('old')
                                setRedirect(`${other_links.redirect_link}?user_login=${userLogin.replace('+', '')}&lang=uk`)
                            }
                        }
                    } else {
                        if (data?.error) {
                            setErrorMessage(data.error)
                            Error(true);
                        } else {
                            setErrorMessage('Виникла помилка, спробуйте пізніше')
                            Error(true);
                        }
                    }
                })
                .catch((e) => {
                    setErrorMessage(e.message)
                    Error(true);
                });
        }
    }

    useEffect(() => {
        if (userLogin.length > 12) {
            setError(false);
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [userLogin]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                callMyFunction();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    return (
        <>
            <div className={`contacts_forms registration blur ${newClass}`}>
                <div className='bg-form' />
                {redirect ? <div className="sub_form column m-t-25">
                    <div className="icon">
                        <img alt=""
                             src={queryString.parse(redirect).login && queryString.parse(redirect).login === 'registration'
                                 ? bonus
                                 : new_account}
                        />
                    </div>
                    {queryString.parse(redirect).login && queryString.parse(redirect).login === 'registration'
                        ? <>
                            <h2>Вітаємо!</h2>
                            <p className='light'>ChampionCasino зустрічає кожного нового Користувача вітальним бонусом: 100 грн на рахунок і 100 грн до персонального бонусу за реєстрацію з верифікацією за допомогою Державного сервісу.</p>
                        </>
                        : <>
                            <h2>Акаунт вже створено!</h2>
                            <p className='light'>Цілий світ розваг у ваших руках.</p>
                        </>
                    }
                </div> : null}
                <form
                    autoComplete="off"
                    onSubmit={e => e.preventDefault()}
                >
                    {errorMessage
                        ? <div className="required relative m_bottom">
                            <p className="required_text">{errorMessage}</p></div>
                        : null
                    }
                    {redirect
                        ? null
                        : <div className={`box ${error ? 'required' : ''}`}>
                            <div className="field">
                                <label htmlFor="phone">Номер телефону (логін)</label>
                                <InputSelect
                                    value={userLogin}
                                    setValue={setUserLogin}
                                    buttonDisabled={buttonDisabled}
                                    isoData={isoData}
                                    setIso={setIso}
                                />
                                {error ? <p className="required_text">Помилка телефону</p> : null}
                            </div>
                        </div>
                    }
                    <div className={`box button ${(!redirect || (redirect && (queryString.parse(redirect).login && queryString.parse(redirect).login === 'registration'))) ? 'background' : ''}`}>
                        {redirect
                            ? <a rel="noreferrer"
                                 href={redirect}>
                                <span>
                                    {queryString.parse(redirect).login && queryString.parse(redirect).login === 'registration'
                                        ? 'Продовжити'
                                        : 'Увійти'
                                    }
                                </span></a>
                            : <button
                                type="button"
                                disabled={buttonDisabled}
                                onClick={callMyFunction}
                                className={loading ? 'button_load' : null}
                            >{loading
                                ? <div className="new_load"/>
                                : <span>Продовжити</span>}
                            </button>
                        }
                    </div>
                </form>
                {!redirect
                    ? <div className="sub_form">
                        <p>
                            Натискаючи кнопку «Продовжити», я підтверджую, що мені є 21 рік і погоджуюсь з
                            <a href={other_links.pravila_ta_umovi}
                               rel="noreferrer"
                               target='_blank'
                            > правилами і умовами </a>
                            сайту.
                        </p></div>
                    : null
                }
            </div>
            <Footer />
        </>
    );
};

export default IndexPage;